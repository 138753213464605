import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { useEffect, useRef } from 'react';
import useStyles from './style';

const ServicePanelInfo = () => {
  const classes = useStyles();
  const panelRef = useRef(null);

  const speakText = (text) => {
    if ('speechSynthesis' in window) {
      const utterance = new SpeechSynthesisUtterance(text);
      
      const voices = window.speechSynthesis.getVoices();
      const chosenVoice = voices.find(voice => voice.name === 'Google português');

      if (chosenVoice) {
        utterance.voice = chosenVoice;
      }
      
      utterance.lang = 'pt-BR';
      
      window.speechSynthesis.speak(utterance);
    } else {
      console.error("Seu navegador não suporta SpeechSynthesis.");
    }
  };
  
  useEffect(() => {
    const currentPassword = 'N - 0003';
    const currentGuiche = '04';
    /* const speakTextAtInterval = () => {
      const speechText = `Senha ${currentPassword}, Guichê ${currentGuiche}`;
      speakText(speechText);
    };

    const intervalId = setInterval(speakTextAtInterval, 15000);
    return () => clearInterval(intervalId); */
  }, []);

  return (
    <Box className={classes.main}>
      <Box className={classes.current}>
        <Typography variant="h6" className={classes.label}>Última Senha chamada</Typography>
      </Box>

       <Box className={classes.currentInfo}>
          <Box className={classes.currentBox}>
            <Typography variant="h4" className={classes.currentLabel}>Senha</Typography>
            <Typography variant="h3" className={classes.currentNumber}>N - 0003</Typography>
          </Box>
          <Box className={classes.currentBox}>
            <Typography variant="h4" className={classes.currentLabel}>GUICHÊ</Typography>
            <Typography variant="h3" className={classes.currentNumber}>04</Typography>
          </Box>
          {/* <Typography variant="h3" className={classes.noInfo}>Nenhuma senha em andamento no momento</Typography> */}
       </Box>

      <Box className={classes.previous}>
         <Box className={classes.current}>
           <Typography variant="h6" className={classes.label}>Senhas Anteriores</Typography>
         </Box>
       <TableContainer component={Paper} style={{boxShadow: 'none', backgroundColor: 'transparent'}}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableHeaderCell}>Senha</TableCell>
                <TableCell className={classes.tableHeaderCell}>Guichê</TableCell>
                <TableCell className={classes.tableHeaderCell}>Horário Chamado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
              <TableRow>
                <TableCell className={classes.tableRow} component="th" scope="row">G - 0002</TableCell>
                <TableCell className={classes.tableRow}>04</TableCell>
                <TableCell className={classes.tableRow}>09:08</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className={classes.tableRow} component="th" scope="row">G - 0001</TableCell>
                <TableCell className={classes.tableRow}>04</TableCell>
                <TableCell className={classes.tableRow}>08:48</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ServicePanelInfo;
