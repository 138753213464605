import { Box, Typography } from "@material-ui/core";
import useStyles from "./style";

const Header = () => {
  const classes = useStyles();

  return (
    <Box className={classes.main}>
      <Typography className={classes.title}>IMASUL - INSTITUTO DE MEIO AMBIENTE     09:26   09/05/2024</Typography>
    </Box>
  );
}

export default Header;