import Header from "./components/Header";
import ServicePanelInfo from "./components/panel";

const ServicePanel = () => {
  return (
    <>
      <Header />
      <ServicePanelInfo />
    </>
  )
}

export default ServicePanel;