import { BrowserRouter, Route, Switch } from "react-router-dom";
import { RouteLayout } from "./Components";
import BlankLayout from "./Components/Layouts/BlankLayout";
import WithHeader from "./Components/Layouts/WithHeader";
import AttendanceOnlineChat from "./Pages/AttendanceOnlineChat";
import Attendances from "./Pages/Attendances";
import Home from "./Pages/Home";
import InitPage from "./Pages/InitPage";
import LoginCallback from "./Pages/LoginCallback";
import OrganicSchedules from "./Pages/OrganicSchedules";
import PrintScheduler from "./Pages/PrintScheduler";
import Schedules from "./Pages/Schedules";
import SchedulesInternal from "./Pages/SchedulesInternal";
import SearchSchedules from "./Pages/SearchSchedules";
import ServicePanel from "./Pages/ServicePanel";
import SetTicketOffice from "./Pages/SetTicketOffice";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login/callback/" component={LoginCallback} />
        <RouteLayout
          path="/"
          exact
          component={InitPage}
          layout={BlankLayout}
          isAuth
        />
        <RouteLayout
          path="/select-ticket-office"
          exact
          component={SetTicketOffice}
          layout={BlankLayout}
          isAuth
        />
        <RouteLayout
          path="/home"
          exact
          component={Home}
          layout={WithHeader}
          isAuth
        />
        <RouteLayout
          path="/agendamento"
          exact
          component={Schedules}
          layout={WithHeader}
          isAuth
        />
        <RouteLayout
          path="/agendamento_interno"
          exact
          component={SchedulesInternal}
          layout={WithHeader}
          isAuth
        />
        <RouteLayout
          path="/consulta_agendamento"
          exact
          component={SearchSchedules}
          layout={WithHeader}
          isAuth
        />
        <RouteLayout
          path="/atendimento"
          exact
          component={Attendances}
          layout={WithHeader}
          isAuth
        />
        <RouteLayout
          path="/atendimento/:id"
          exact
          component={AttendanceOnlineChat}
          layout={WithHeader}
          isAuth
        />
        <RouteLayout
          path="/atendimento_diario"
          exact
          component={OrganicSchedules}
          layout={WithHeader}
          isAuth
        />
        <RouteLayout
          path="/imprimir_agendamento"
          exact
          component={PrintScheduler}
          layout={BlankLayout}
          isAuth
        />
         <RouteLayout
          path="/painel_de_atendimento"
          exact
          component={ServicePanel}
          layout={BlankLayout}
          isAuth
        />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
